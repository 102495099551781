import React from 'react';
import 'antd/dist/antd.css';
// import { Container, Row, Col } from "reactstrap";

import { Form } from 'antd';
import Gallery from './Gallery';
import './styles.css';
import './AboutUs.css';

import Jisu from './img/jisu.png';
import Liudas from './img/liudas.png';

class DynamicRule extends React.Component {
  check = () => {
    this.props.form.validateFields(err => {
      if (!err) {
        console.info('success');
      }
    });
  };

  render() {
    return (
      <div xs={{ span: 18, offset: 0 }} className="RSVP">
        <div className="ourstory" id="ourstory">
          <div className="title">
            <h3>Our Story</h3>
          </div>
          <div className="flex center">
            <div className="imgCon">
              <img src={Liudas} alt="liudas" />
            </div>
            <div className="imgCon">
              <img src={Jisu} alt="jisu" />
            </div>
          </div>
          <p className="des">
            Liudas was born in Lithuania and Jisu was born in Korea. We grew up
            in vastly different worlds over the past 30 years. One, a developer,
            and the other, a designer, we met for the first time in Vancouver,
            Canada, in 2017. Over time, we got to know one another and
            discovered how well we complemented each other. In 2018, we have
            decided to embark on a journey together for the rest of our lives.
            <br />
            We are going to have our wedding on August 11th, 2019, and we would
            like to invite our friends and family to celebrate the first big
            step of our journey together.
          </p>
          {/* <p className="bible">
            <span>John 15:12:</span> “My command is this: Love each other as I
            have loved you."
          </p> */}
        </div>

        <div className="gallery" id="gallery">
          <div className="title">
            <h3>Gallery</h3>
          </div>
          <Gallery />
        </div>
      </div>
    );
  }
}

const RSVP = Form.create({ name: 'dynamic_rule' })(DynamicRule);
export default RSVP;
