import React from "react";
import axios from "axios";
import "antd/dist/antd.css";
import "./RSVP.css";
import "./Popup.css";
import { Button, Modal, Form, Input, Radio, InputNumber, Row, Col } from "antd";

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const RSVPForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: 1,
        done: false
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange = e => {
      this.setState({
        value: e.target.value
      });
    };
    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          axios
            .post("https://wedding-239104.appspot.com/save", values)
            // .post('http://localhost:8080/save', values)
            .then(function(response) {
              // console.log(response);
              document.getElementById("modal-thankyou").style.display = "block";
              document.getElementById("modal-body").style.display = "none";
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    };
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="*RSVP*"
          okText="Create"
          style={{ top: 20 }}
          onCancel={onCancel}
          onOk={onCreate}
          footer={null}
          className="text-center"
        >
          <div
            id="modal-thankyou"
            className="RSVPbtn"
            style={{ display: "none" }}
          >
            <h2>Thank you!</h2>
            <br />
            <br />
            <br />
            <p className="small">
              While your presence at our wedding is all that we wish for, if you
              want to give us a gift, we will be grateful for a small cash
              donation towards our honeymoon and our future together.
            </p>
            <Button
              target="_blank"
              href="https://www.gofundme.com/dthzm-wedding-donation"
            >
              <p>Donate Online</p>
            </Button>
          </div>

          <div id="modal-body">
            <div>
              {/* <p>We are invite you to our wedding. We hope you can make it!</p> */}
              <div className="popup-date">
                <h4>Wedding Details</h4>
                <p>Dress code: semi formal</p>
                <p className="date">August 11, 2019 | 5:30 PM</p>
              </div>
            </div>
            <Form layout="vertical" onSubmit={e => this.handleSubmit(e)}>
              <Form.Item className="collection-create-form_last-form-item">
                {getFieldDecorator("going", {
                  rules: [
                    {
                      required: true,
                      message: "Are you going?"
                    }
                  ]
                })(
                  <RadioGroup>
                    <Radio value={"Y"}>Yes, I am attending</Radio>
                    <Radio value={"N"}>Sorry, I can not attending</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              <Row className="flex name-con">
                <Col xs={24} sm={12}>
                  <Form.Item className="flex num flexWrap name" label="Name:">
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "Please write your name"
                        }
                      ]
                    })(<Input type="textarea" placeholder="hello@gmail.com" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item className="flex num flexWrap email" label="Email:">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: "Please write your email"
                        }
                      ]
                    })(<Input type="textarea" placeholder="hello@gmail.com" />)}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                className="flex num flexWrap textbox"
                label="How do you know Liudas and Jisu?"
              >
                {getFieldDecorator("howDoYouKnow", {
                  rules: [
                    {
                      required: true,
                      message: "Please write your here"
                    }
                  ]
                })(
                  <Input type="textarea" placeholder="e.g. Friend of Liudas" />
                )}
              </Form.Item>
              <Form.Item
                className="flex num flexWrap"
                label="Total number of your guests, including yourself:"
              >
                {getFieldDecorator("guests", {
                  rules: [
                    {
                      required: true,
                      message: "Please add your self"
                    }
                  ]
                })(<InputNumber min={0} max={2} />)}
              </Form.Item>
              <Form.Item
                className="flex num flexWrap comments"
                label="Comments:"
              >
                {getFieldDecorator("comment")(<TextArea type="textarea" />)}
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      );
    }
  }
);

class CollectionsPage extends React.Component {
  state = {
    visible: false
  };
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  // handleCreate = e => {
  //   e.preventDefault();
  //   console.log('[RSVP.js] this', 1);
  //   const form = this.formRef;
  //   form.validateFields((err, values) => {
  //     if (err) {
  //       console.log('[RSVP.js] err', err);
  //       return;
  //     }

  //     console.log('Received values of form: ', values);
  //     form.resetFields();
  //     this.setState({ visible: false });
  //   });
  // };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log("[RSVP.js] here 1", 22);
    this.props.form.validateFields((err, values) => {
      console.log("[RSVP.js] err", err);
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <div className="RSVP" id="rsvp">
        <div className="title">
          <h3>enter the name on your invitation.</h3>
        </div>

        {/* <Form.Item>
            {getFieldDecorator("userName", {
              rules: [
                { required: true, message: "Please input your username!" }
              ]
            })(
              <div className="nameVerification">
                <Input placeholder="e.g. Jisu Lee" />{" "}
              </div>
            )}
          </Form.Item> */}

        <div className="RSVPbtn">
          <Button type="" onClick={this.showModal}>
            <p>RSVP</p>
          </Button>
        </div>

        <RSVPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleSubmit}
        />
      </div>
    );
  }
}
export default CollectionsPage;
