import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ImgOne from './img/1.jpg';
import ImgTwo from './img/2.jpg';
import ImgThree from './img/3.jpg';
import ImgFour from './img/4.jpg';
import ImgFive from './img/5.jpg';
import ImgSix from './img/6.jpg';
import ImgSeven from './img/7.jpg';
import ImgEight from './img/8.jpg';
import ImgNine from './img/9.jpg';
import ImgTen from './img/10.jpg';
// import ImgEleven from './img/11.jpg';
import ImgTwelve from './img/12.jpg';
import ImgThirteen from './img/13.jpg';
import Img14 from './img/14b.jpg';
import Img15 from './img/15b.jpg';
import Img16 from './img/16b.jpg';
import Img17 from './img/17b.jpg';
import Img18 from './img/18b.jpg';
import Img19 from './img/19b.jpg';
import Img20 from './img/20b.jpg';
import Img21 from './img/21b.jpg';
import Img22 from './img/22b.jpg';
import Img23 from './img/23b.jpg';
import Img24 from './img/24b.jpg';
import Img25 from './img/25b.jpg';
import Img26 from './img/26b.jpg';
import './Gallery.css';

const Gallery = () => {
  const handleOnDragStart = e => e.preventDefault();
  return (
    <AliceCarousel mouseDragEnabled>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgOne}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgTwo}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>

      <div className="ImgCon">
        <img
          alt="im"
          src={ImgFour}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgFive}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgSix}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgSeven}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgEight}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgNine}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgTen}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>

      <div className="ImgCon">
        <img
          alt="im"
          src={ImgTwelve}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgThree}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={ImgThirteen}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img14}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img15}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img16}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img17}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img18}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img19}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img20}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img21}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img22}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img23}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img24}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img25}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
      <div className="ImgCon">
        <img
          alt="im"
          src={Img26}
          onDragStart={handleOnDragStart}
          className="yours-custom-class"
        />
      </div>
    </AliceCarousel>
  );
};
export default Gallery;
