import React from 'react';
import 'antd/dist/antd.css';
import { Button, Row, Col, Icon } from 'antd';

import './Location.css';
import Map from './img/map2.png';

class Location extends React.Component {
  render() {
    return (
      <div className="details" id="details">
        <div className="title">
          <h3>The Details</h3>
        </div>
        <div>
          <p>
            While your presence at our wedding is all that we wish for, if you
            want to give us a gift, we will be grateful for a small cash
            donation towards our honeymoon and our future together.
          </p>
          <div className=" donation-btn">
            <Button
              target="_blank"
              href="https://www.gofundme.com/dthzm-wedding-donation"
            >
              <p>Donate Online</p>
            </Button>
          </div>
        </div>
        <Row className="flex center details-text">
          <Col span={1} order={1} />
          <Col span={8} order={2}>
            <Icon type="clock-circle" />
            <h4>When</h4>
            <p>August 11th 2019 at 5:30pm</p>
          </Col>
          <Col span={2} order={3} />
          <Col span={8} order={4}>
            <Icon type="environment" />
            <h4>Where</h4>
            <p>#112 9547 152 St, Surrey</p>
          </Col>
          <Col span={1} order={5} />
        </Row>
        <div className="ImgCon mapImg">
          <a
            href="https://www.google.com/maps/place/9547+152+St,+Surrey,+BC+V3R+5Y5/@49.1760289,-122.8368435,13z/data=!4m5!3m4!1s0x5485d74364b69b3f:0xe3909302bbe2d359!8m2!3d49.1760272!4d-122.8018245"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Map} alt="map" />
          </a>
        </div>
      </div>
    );
  }
}

export default Location;
