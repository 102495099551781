import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { BackTop } from 'antd';

import Nav from './Nav';
import RSVP from './RSVP';
import AboutUs from './AboutUs';
import Location from './Location';

import './styles.css';

import Topbanner from './img/topbanner.png';

function App() {
  return (
    <Container className="App">
      <Row id="home">
        <div className="topbanner">
          <img alt="im" src={Topbanner} />
        </div>
        <div className="toptext">
          <h1>Liudas Sodonis </h1>
          <div className="add">and</div>
          <h1>Jisu Lee </h1>
        </div>
        <Nav />
        <RSVP />
        <AboutUs />
        <Location />
        <div>
          <BackTop />
        </div>
        <footer>
          <div className="copyright">@copyright Liudas and Jisu</div>
          <div>English | Lithuanian | 한국어</div>
        </footer>
      </Row>
    </Container>
  );
}
export default App;
